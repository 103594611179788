/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../css/base.scss');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
window.$ = window.jQuery = require('jquery');

// the bootstrap module doesn't export/return anything
require('bootstrap');

$(function () {
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover();

    $('#formConectare').on('submit', function () {

    });

    $('#formInregistrare input[name="_email"]').on('change', function () {
        var $form = $(this).closest('form');
        var url = $form.attr('action');
        var $email = $(this);

        var formVars = {
            '_email': $email.val(),
            'checkEmail': true
        }

        $.ajax({
            type: 'post',
            url: url,
            data: formVars,
            success: function (rasp) {
                if (rasp.email_exist === true) {
                    $email.removeClass('is-valid').addClass('is-invalid');
                } else {
                    $email.removeClass('is-invalid').addClass('is-valid');
                }
            }
        });
    });

    $('#formInregistrare input[name="_username"]').on('change', function () {
        var $form = $(this).closest('form');
        var url = $form.attr('action');
        var $username = $(this);

        var formVars = {
            '_username': $username.val(),
            'checkUsername': true
        }

        $.ajax({
            type: 'post',
            url: url,
            data: formVars,
            success: function (rasp) {
                if (rasp.username_exist === true) {
                    $username.removeClass('is-valid').addClass('is-invalid');
                } else {
                    $username.removeClass('is-invalid').addClass('is-valid');
                }
            }
        });
    });

    $('#submitFormInregistrare').on('click', function () {
        var $form = $(this).closest('form');
        var url = $form.attr('action');

        var $parola = $form.find('input[name="_parola"]');
        var $confirmaparola = $form.find('input[name="_confirmaparola"]');

        if ($parola.val() !== $confirmaparola.val()) {
            $parola.addClass('is-invalid');
            $confirmaparola.addClass('is-invalid');

            return;
        } else {
            $parola.removeClass('is-invalid');
            $confirmaparola.removeClass('is-invalid');
        }
        var $email = $form.find('input[name="_email"]');
        var $username = $form.find('input[name="_username"]');

        var formVars = {
            '_email': $email.val(),
            '_username': $username.val(),
            'checkEmail': true,
            'checkUsername': true
        }

        $.ajax({
            type: 'post',
            url: url,
            data: formVars,
            success: function (rasp) {
                if (rasp.email_exist === true) {
                    $email.removeClass('is-valid').addClass('is-invalid');
                } else {
                    $email.removeClass('is-invalid').addClass('is-valid');
                }
                if (rasp.username_exist === true) {
                    $username.removeClass('is-valid').addClass('is-invalid');
                } else {
                    $username.removeClass('is-invalid').addClass('is-valid');
                }

                if (rasp.email_exist !== true && rasp.username_exist !== true) {
                    return $form.submit();
                }
            }
        });

//        $.ajax({
//            type: 'post',
//            url: url,
//            data: formVars,
//            success: function (rasp) {
//                if (rasp.succes === true) {
//                    var alert = $form.find('.alert-success');
//
//                    alert.html("<p>" + rasp.mesaje[0] + "</p>");
//                    alert.show();
//                    $form.find('.alert-danger').hide();
//                } else {
//                    var alert = $form.find('.alert-danger');
//
//                    var boxhtml = '';
//                    for (var k = 0; k < rasp.mesaje.length; k++) {
//                        boxhtml = boxhtml + "<p>" + rasp.mesaje[k] + "</p>";
//                    }
//
//                    alert.html(boxhtml);
//                    alert.show();
//                    $form.find('.alert-success').hide();
//                }
//            }
//        });
    });
});
